<template>
    <main class="cus-payment blank-aside-js min-h-100vh page_bg_grey" :class = "(isOpenAside=='true')?'blank-aside':''">
        <banner-section location="payments_home-top"/>
        <section v-if="Array.isArray(billsSuppliers) && billsSuppliers.length" class="payment__brands">
            <div v-for="(supplier_item, index) in billsSuppliers" :key="index">
                <router-link :to="{name: 'SupplierPayment', params: {supplier_id: supplier_item.id }}" tag="a" exact>
                    <SupplierCard
                        class="pointer"
                        :text="supplier_item.name"
                        :logo="supplier_item.image"
                        :bgColor="supplier_item.logo_background_color"
                        :titleBgColor="supplier_item.logo_title_background"
                        :fontColor="supplier_item.font_color"
                    >
                    </SupplierCard>
                </router-link>
            </div>
        </section>

        <section v-if="Array.isArray(billsSuppliersWithBestsellers) && billsSuppliersWithBestsellers.length" class="payment__bestsellers">
            <h2 class="title payment__title">{{ $t("Best Sellers") }}</h2>

            <div class="payment__slider">

                <div class="payment__wrapper owl-carousel js-payment-bestsellers">

                    <div v-for="(supplier_item, supplier_index) in billsSuppliersWithBestsellers" :key="supplier_index" class="category-slider payment__slider-item">

                        <div class="category-slider__img-wrapper ">
                            <img class="category-slider__img payment__slider-image" :src="supplier_item.image" :style="{'background-color': supplier_item.logo_background_color}" alt="">
                        </div>

                        <div class="category-slider__info-block">

                            <div v-for="(bestseller, bestseller_index) in supplier_item.bestsellers" :key="bestseller_index" class="category-slider__info">

                                <router-link :to="{name: 'SupplierPayment', params: {supplier_id: bestseller.supplier_id }}" tag="a" class="link category-slider__link" exact>

                                    <p class="text category-slider__product-name">
                                        {{ bestseller.name }}
                                    </p>

                                    <div v-if="bestseller.price != null" class="category-slider__price">
                                        <b class="category-slider__price-value">
                                            {{ ' ' + bestseller.price + ' ' }}
                                        </b>
                                        <b class="category-slider__current">
                                            {{ '₪' }}
                                        </b>
                                    </div>

                                </router-link>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="category-slider__controls ">

                    <button class="button category-slider__control category-slider__control_prev">
                        <div class="">
                            <img class="" src="assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                    <button class="button category-slider__control category-slider__control_next">
                        <div class="">
                            <img class="" src="assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                </div>

            </div>

        </section>

    </main>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import SupplierCard from '@/components/endUser/SupplierCard';
    import appConfig from '../appConfig'
    import BannerSection from "../components/BannerSection";

    export default {
        name: 'Payment',
        components: {
            BannerSection,
            SupplierCard
        },
        data: () => {
            return {
                reInitBestsellersCarouselNeeded: false
            }
        },
        mounted() {
            moduleCommunication.init()
            moduleDropdown.init()
            moduleEntertainment.init()
            moduleFavorites.init()
            moduleFilter.init()
            moduleGift.init()
            moduleModalBooking.init()
            moduleModalDiscount.init()
            modulePayment.init()
            moduleRegister.init()
            moduleTourism.init()
            moduleTourismProd.init()
            moduleTrip.init()
        },
        computed: {
            ...mapState({
                supplier_items: state => state.supplier.supplier_items,
                suppliers_products: state => state.supplier.suppliers_products,
                product_bestseller_tag_id: state => state.supplier.product_bestseller_tag_id
            }),
            billsSuppliers() {
                let billsSuppliers = this.supplier_items && this.supplier_items.hasOwnProperty('bills') && Array.isArray(this.supplier_items.bills) && this.supplier_items.bills.length ? this.supplier_items.bills : []
                billsSuppliers = billsSuppliers.filter(supplier => supplier.status === 1).sort((a,b) => a.order - b.order);

                return billsSuppliers;
            },
            billsSuppliersWithBestsellers() {
                let billsSuppliersWithBestsellers = []

                if (this.billsSuppliers && this.billsSuppliers.length && this.product_bestseller_tag_id) {
                    for (let supplier of this.billsSuppliers) {
                        let supplier_products = this.suppliers_products.hasOwnProperty(supplier.id)
                            ? this.suppliers_products[supplier.id] : []
                        let supplier_bestsellers = []

                        if (supplier_products) {
                            for (let supplier_product_type in supplier_products) {
                                for (let supplier_product of supplier_products[supplier_product_type]) {
                                    if (supplier_product.hasOwnProperty('tags') && supplier_product.tags.length
                                        && supplier_product.tags.includes(this.product_bestseller_tag_id)) {
                                        supplier_bestsellers.push(supplier_product)
                                    }
                                }
                            }
                        }

                        if (supplier_bestsellers.length) {
                            billsSuppliersWithBestsellers.push({...supplier, bestsellers: supplier_bestsellers})
                        }
                    }
                }

                let obj = this
                obj.reInitBestsellersCarouselNeeded = true
                setTimeout(function () {
                    if (obj.reInitBestsellersCarouselNeeded) {
                        obj.reInitBestsellersCarouselNeeded = false
                        obj.reInitBestsellersCarousel()
                    }
                }, 500)

                return billsSuppliersWithBestsellers
            },
        },
        created() {
            this.getAllSupplierItems({params: {filter: true}})
            this.getProductTags()
        },
        methods: {
            ...mapActions('supplier', {
                getAllSupplierItems: 'getAll',
                getProductTags: 'getProductTags',
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            reInitBestsellersCarousel() {
                $('.js-payment-bestsellers').each(function () {
                    $(this).trigger('destroy.owl.carousel');
                })
                modulePayment.init()
            }
        }
    }
</script>

<style>
    .cus-payment {
        flex-basis: 100%;
        padding: 0 90px 50px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
    }
    .category-slider__product-name {
        max-width: 72%;
    }
    .category-slider__link::before {
        top: 50%;
        transform: translateY(-50%);
    }
    .category-slider__link::after {
        top: calc(50% - 10px);
    }

    .payment__brands {
        justify-content: unset;
        align-content: flex-start;
    }

    .payment__brand {
        flex-basis: calc((100% - 100px) / 6);
        margin: 10px;
        box-sizing: border-box;
    }

    @media screen and (min-width: 1366px) {
        .payment__brand:nth-child(n+6) {
            margin-top: 10px;
        }

        .payment__brand:not(:nth-child(5n)) {
            margin-left: 10px;
        }

        .payment__brand:nth-child(n + 7) {
            margin-top: 10px;
        }

        .payment__brand:nth-child(6n + 1) {
            margin-right: 0;
        }

        .payment__brand:nth-child(6n + 6) {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 1365px) and (min-width: 768px) {
        .payment__brand {
            flex-basis: calc((100% - 60px) / 4);
        }

        .payment__brand:nth-child(n) {
            margin-left: 10px;
        }

        .payment__brand:nth-child(n + 3) {
            margin-top: 10px;
        }

        .payment__brand:not(:nth-child(5n)) {
            margin-left: 10px;
        }

        .payment__brand:nth-child(n + 5) {
            margin-top: 10px;
        }

        .payment__brand:nth-child(4n + 1) {
            margin-right: 0;
        }

        .payment__brand:nth-child(4n + 4) {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 767px) {
        .payment__brand {
            flex-basis: calc((100% - 40px) / 3);
        }

        .payment__brand:nth-child(n) {
            margin-left: 10px;
        }

        .payment__brand:nth-child(n + 2) {
            margin-top: 10px;
        }

        .payment__brand:not(:nth-child(5n)) {
            margin-left: 10px;
        }

        .payment__brand:nth-child(n + 4) {
            margin-top: 10px;
        }

        .payment__brand:nth-child(3n + 1) {
            margin-right: 0;
        }

        .payment__brand:nth-child(3n + 3) {
            margin-left: 0;
        }
        .cus-payment {
            padding: 0;
        }
    }
</style>

<style lang="scss" scoped>
.cus-payment__brand {
    text-align: center;
    margin-bottom: 25px;
    .brand {
        height: 100%;
    }
    .brand__img {
        position: absolute;
        top: 10px;
        height: calc(100% - 44px);
    }
    .payment__brand-text {
        width: 100%;
        height: 24px;
        bottom: 0;
        background: rgba(0,0,0,0.2);
        color: #ffffff;
    }
}
</style>